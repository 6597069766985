import "./home.css";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import GoogleTour from "../../components/GoogleTour/GoogleTour";
import GoogleMaps from "../../components/GoogleMaps/GoogleMaps";
// import ImageRow from "../../components/ImageRow/ImageRow";
import React from 'react';

export default function Home() {
    return (
        <>
            <article className="article">
                <picture className="picture">
                    <img src="https://i.imgur.com/W8esp2f.png" alt="background" className="image" />
                </picture>
                <h1 className="header">THE PARTY STARTS WITH US</h1>
            </article>

            <div className="businfo">
                <h1 className="title2">VISIT OUR SHOWROOM</h1>
                <p className="title addy">
                    <a className="title addy" href="https://goo.gl/maps/t9MAizKhZt6iyDXS9">Address: 7232 Stockton Blvd. Sacramento, CA 95823</a>
                </p>
                <p>
                    <a className="title addy" href="tel:+19164248300">Phone: (916)-424-8300</a>
                </p>

                <div className="hours-container">
                    <div className="hours-section">
                        <p className="title addy">
                            <strong className="seasonHead">Winter Hours (Jan-Mar): </strong>
                        </p>
                        <span className="bold-text"><strong>Monday - Wednesday</strong>:</span> 9:00 AM - 4:00 PM<br />
                        <span className="bold-text"><strong>Thursday</strong>:</span> APPOINTMENTS ONLY<br />
                        <span className="bold-text"><strong>Friday</strong>:</span> 9:00 AM - 4:00 PM<br />
                        <span className="bold-text"><strong>Saturday</strong>:</span> 9:00 AM - 1:00 PM<br />
                        <span className="bold-text"><strong>Sunday</strong>:</span> CLOSED
                    </div>

                    <div className="hours-section">
                        <p className="title addy">
                            <strong className="seasonHead">Summer Hours (April - Dec):</strong>
                        </p>
                        <span className="bold-text"><strong>Monday - Wednesday</strong>:</span> 9:00 AM - 5:00 PM<br />
                        <span className="bold-text"><strong>Thursday</strong>:</span> APPOINTMENTS ONLY<br />
                        <span className="bold-text"><strong>Friday</strong>:</span> 9:00 AM - 5:00 PM<br />
                        <span className="bold-text"><strong>Saturday</strong>:</span> 9:00 AM - 1:00 PM<br />
                        <span className="bold-text"><strong>Sunday</strong>:</span> CLOSED
                    </div>
                </div>
            </div>

            <div className="contact-buttons">
                <a href="tel:+19164248300" className="contact">Call Us!</a>
                <a href="mailto:upr_inc@yahoo.com" className="contact">Email Us!</a>
            </div>

            {/* <ImageRow /> */}

            <div className="grid-container">
    <div className="card areas-we-rent">
        <h2 className="title2">Our Rentals</h2>
        <ul className="rent-list">
        <li><a href="/bar" className="area-link btn">Bar & Beverage</a></li>
                            <li><a href="/canopy" className="area-link btn">Canopy</a></li>
                            <li><a href="/chairs" className="area-link btn">Chairs</a></li>
                            <li><a href="/china" className="area-link btn">China</a></li>
                            <li><a href="/Concession" className="area-link btn">Concession</a></li>
                            <li><a href="/Cookware" className="area-link btn">Cookware</a></li>
                            <li><a href="/Decoration" className="area-link btn">Decoration</a></li>
                            <li><a href="/Flatware" className="area-link btn">Flatware</a></li>
                            <li><a href="/Glassware" className="area-link btn">Glassware</a></li>
                            <li><a href="/Linen" className="area-link btn">Linen</a></li>
                            <li><a href="/Misc" className="area-link btn">Miscellaneous</a></li>
                            <li><a href="/Serving" className="area-link btn">Serving</a></li>
                            <li><a href="/Staging" className="area-link btn">Staging & Flooring</a></li>
                            <li><a href="/Tables" className="area-link btn">Tables</a></li>
        </ul>
    </div>

    <div className="card areas-we-serve">
        <h2 className="title2">Service Areas</h2>
        <ul className="areas-list">
        <li><a href="/Auburn" className="area-link btn">Auburn</a></li>
                            <li><a href="/Clarksburg" className="area-link btn">Clarksburg</a></li>
                            <li><a href="/Courtland" className="area-link btn">Courtland</a></li>
                            <li><a href="/Davis" className="area-link btn">Davis</a></li>
                            <li><a href="/Dixon" className="area-link btn">Dixon</a></li>
                            <li><a href="/EldoradoHills" className="area-link btn">El Dorado Hills</a></li>
                            <li><a href="/ElkGrove" className="area-link btn">Elk Grove</a></li>
                            <li><a href="/Folsom" className="area-link btn">Folsom</a></li>
                            <li><a href="/Galt" className="area-link btn">Galt</a></li>
                            <li><a href="/GraniteBay" className="area-link btn">Granite Bay</a></li>
                            <li><a href="/Lincoln" className="area-link btn">Lincoln</a></li>
                            <li><a href="/Lodi" className="area-link btn">Lodi</a></li>
                            <li><a href="/Loomis" className="area-link btn">Loomis</a></li>
                            <li><a href="/RanchoCordova" className="area-link btn">Rancho Cordova</a></li>
                            <li><a href="/Rocklin" className="area-link btn">Rocklin</a></li>
                            <li><a href="/Roseville" className="area-link btn">Roseville</a></li>
                            <li><a href="/Sacramento" className="area-link btn">Sacramento</a></li>
                            <li><a href="/WalnutGrove" className="area-link btn">Walnut Grove</a></li>
                            <li><a href="/WestSacramento" className="area-link btn">West Sacramento</a></li>
                            <li><a href="/Wilton" className="area-link btn">Wilton</a></li>
                            <li><a href="/Woodland" className="area-link btn">Woodland</a></li>
        </ul>
    </div>
</div>

            

<div className="map-tour-container">
    <div className="google-map">
        <GoogleMaps />
    </div>
    <div className="google-tour">
        <GoogleTour />
    </div>
</div>


            <ImageCarousel />

        </>
    );
}
